import React from "react"
import { Link, graphql, PageProps } from "gatsby"
import Layout from "../components/layouts/Layout2"
import { Hero, Breadcrumb, Map } from "../components/slices"
import { renderMetaTags } from "../utils"
import { Helmet } from "react-helmet"

import { SliceRenderer } from "../components/slices"

interface StandardPageProps {
  datoCmsStandardContentPage: {
    title: string
    subtitle: string
    button: string
    url: string
    mainImage: {
      url: string
    }
    pageContent: string
    seoMetaTags: {
      tags
    }
  }
}

interface breadcrumbItem {
  title: string
  url: string
}

const StandardPage = ({ data, location }: PageProps<StandardPageProps>) => {
  const { title, subtitle, button, seoMetaTags, mainImage, pageContent } =
    data.datoCmsStandardContentPage
  let breadcrumbData: breadcrumbItem[] = [{ title: "Home", url: "/" }]

  breadcrumbData = [
    ...breadcrumbData,
    {
      title,
      url: location.pathname,
    },
  ]

  const heroProps = {
    title,
    subtitle,
    mainImage,
    button,
    variation: "small",
  }
  return (
    <Layout>
      {seoMetaTags.tags && <Helmet>{renderMetaTags(seoMetaTags.tags)}</Helmet>}
      <Hero {...heroProps} />
      <div className="container -pinched">
        <Breadcrumb data={breadcrumbData} />
        <SliceRenderer slices={pageContent} variation="small" />
      </div>
      <div className="call-to-action">
        <div className="container">
          <div className="call-to-action__inner">
            <p className="[ title h3 ]">Book your appointment now</p>
            <Link className="button" to="/contact">
              Contact us
            </Link>
          </div>
        </div>
      </div>
      <Map />
    </Layout>
  )
}

export const query = graphql`
  query ContentPage($url: String!) {
    datoCmsStandardContentPage(url: { eq: $url }) {
      title
      url
      seoMetaTags {
        tags
      }
      mainImage {
        url
      }
      pageContent {
        __typename
        ... on DatoCmsText {
          text {
            value
          }
        }
        ... on DatoCmsImage {
          image {
            url
          }
        }
      }
    }
  }
`

export default StandardPage
